<template>
    <el-card>
        <div style="text-align: left">
            <i :class="cardIcon" :style="{ color: cardItemColor }"></i>
            {{ cardTitle }}
        </div>
        <div style="text-align: left; margin-top: 12px; line-height: 1.6">
            <li v-for="(item, index) in messages" :key="index">
                {{ item }}
            </li>
        </div>
    </el-card>
</template>

<script lang="ts" setup>
const cardTitle = "最新文章";
const cardIcon = "fa-solid fa-clock-rotate-left";
const cardItemColor = `hsl(200, 100%, 60%)`;
const messages: string[] = [
    "SignUp —— 适用于大学/中小学/职场的",
    "Leetcode.3102 最小化曼哈顿距离",
    "欧几里得算法 —— 计算机中的最大公约数",
];
</script>
