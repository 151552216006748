<!-- src/App.vue -->
<template>
    <div id="app">
        <div class="bg-overlay" />
        <NavBar />
        <router-view style="margin-top: 60px" />
        <Footer />
    </div>
</template>

<script lang="ts" setup>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";

const bgUrl = require("@/assets/interstellar.jpg");
const bgFit = "contain";

defineOptions({
    name: "App",
});
</script>

<style>
body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

#app {
    font-family: Inter, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/interstellar.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    transform: scale(1.05);
    filter: blur(6px);
    z-index: -1;
}
</style>
