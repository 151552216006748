<template>
    <el-card>
        <div style="text-align: left">
            <i :class="cardIcon" :style="{ color: cardItemColor }"></i>
            {{ cardTitle }}
        </div>
        <div style="margin-top: 12px; line-height: 1.6">
            <div v-for="(item, index) in messages" :key="index">
                <el-button link="true">
                    {{ item }}
                </el-button>
            </div>
        </div>
    </el-card>
</template>

<script lang="ts" setup>
const cardTitle = "标签";
const cardIcon = "fa-solid fa-tags";
const cardItemColor = `hsl(200, 100%, 60%)`;
const messages: string[] = [
    "网络钓鱼攻击",
    "智慧曲园",
    "手把手教程系列",
    "图床",
    "Render",
    "网络安全原理实验报告",
    "Typora",
    "逆向技术",
    "Vercel",
    "技术教程",
    "U校园",
    "前进的动力",
    "SQL注入",
    "文案",
    "CTF",
    "CTF_tools",
    "C++",
    "流量分析",
    "写作",
    "Android",
    "膜拜大佬",
    "计算机",
    "burpsuite",
    "蓝桥",
    "Github",
    "汇编语言",
    "Windows技术分享",
    "选课指北",
    "假条",
    "QQbot",
    "Typora-plugin",
    "加盐",
    "Java",
    "扩展访问控制列表",
    "Github-actions",
    "Sakuracat",
    "Crypto",
    "GitHub-pages",
    "How-to-ask",
    "Windows",
];
</script>
