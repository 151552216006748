<template>
    <el-card>
        <div style="text-align: left">
            <i :class="cardIcon" :style="{ color: cardItemColor }"></i>
            {{ cardTitle }}
        </div>
        <div style="text-align: left; margin-top: 12px; line-height: 1.6">
            <div v-for="(item, index) in messages" :key="index">
                {{ item }}
            </div>
        </div>
    </el-card>
</template>

<script lang="ts" setup>
const cardTitle = "网站资讯";
const cardIcon = "fa-solid fa-chart-line";
const cardItemColor = `hsl(200, 100%, 60%)`;
const messages: string[] = [
    "文章数目：",
    "已运行时间：",
    "本站总字数：",
    "本站访客数：",
    "本站总访问量：",
    "最后更新时间：",
];
</script>
