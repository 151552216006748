import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router/router";
import ElementPlus from "./plugins/element";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { createPinia } from "pinia";

const app = createApp(App);

app.config.globalProperties.$axios = axios;

app.use(router);
app.use(ElementPlus);
app.use(createPinia());

app.mount("#app");

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
