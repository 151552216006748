<!-- src/views/Home.vue -->
<template>
    <el-main>
        <el-row :gutter="24" justify="center" style="max-width: 1200px; margin: 0 auto">
            <el-col class="col1" :span="6" style="max-width: 300px">
                <CardPersonal />
                <CardAnnouncement v-if="isAbove767" />
                <CardNewestPost v-if="isAbove767" />
                <CardCategory v-if="isAbove767" />
                <CardTag v-if="isAbove767" />
                <CardPigeonhole v-if="isAbove767" />
                <CardWebsiteInfo v-if="isAbove767" />
                <!-- <CardTest /> -->
            </el-col>
            <el-col class="col2" :xs="24" :sm="14" :md="16" :lg="18" :xl="24">
                <CardMain
                    title="Go 语言是什么？"
                    artical="Go 是一个开源的编程语言，它能让构造简单、可靠且高效的软件变得容易。Go是从2007年末由Robert Griesemer, Rob Pike, Ken Thompson主持开发，后来还加入了Ian ance Taylor, Russ Cox等人，并最终于2009年11月开源，在2012年早些时候发布了Go 1稳定版本。现在Go的开发已经是完全开放的，并且拥有一个活跃的社区。"
                />
                <CardMain
                    title="Bootstrap5 是什么？"
                    artical="Bootstrap 是全球最受欢迎的前端组件库，用于开发响应式布局、移动设备优先的 WEB 项目。Bootstrap5 目前是 Bootstrap 的最新版本，是一套用于 HTML、CSS 和 JS 开发的开源工具集。它支持 Sass 变量和 mixins、响应式网格系统、大量的预建组件和强大的 JavaScript 插件，助你快速设计和自定义响应式、移动设备优先的站点。"
                />
                <CardMain
                    title="HTML 是什么？"
                    artical="超文本标记语言（英语：HyperText Markup Language，简称：HTML）是一种用于创建网页的标准标记语言。您可以使用 HTML 来建立自己的 WEB 站点，HTML 运行在浏览器上，由浏览器来解析。"
                />
                <CardMain
                    title="CSS 是什么？"
                    artical="CSS (Cascading Style Sheets，层叠样式表），是一种用来为结构化文档（如 HTML 文档或 XML 应用）添加样式（字体、间距和颜色等）的计算机语言，CSS 文件扩展名为 .css。通过使用 CSS 我们可以大大提升网页开发的工作效率！在我们的 CSS 教程中，您会学到如何使用 CSS 同时控制多重网页的样式和布局。CSS3 现在已被大部分现代浏览器支持，而下一版的 CSS4 仍在开发中。"
                />
                <CardMain
                    title="JavaScript 是什么？"
                    artical="JavaScript 是 Web 的编程语言。所有现代的 HTML 页面都可以使用 JavaScript。JavaScript 非常容易学。"
                />
                <CardMain
                    title="Vue3 是什么？"
                    artical="Vue.js（读音 /vjuː/, 类似于 view） 是一套构建用户界面的渐进式框架。Vue 只关注视图层， 采用自底向上增量开发的设计。Vue 的目标是通过尽可能简单的 API 实现响应的数据绑定和组合的视图组件。"
                />
                <CardMain
                    title="React 是什么？"
                    artical="React 是一个用于构建用户界面的 JAVASCRIPT 库。React 主要用于构建 UI，很多人认为 React 是 MVC 中的 V（视图）。React 起源于 Facebook 的内部项目，用来架设 Instagram 的网站，并于 2013 年 5 月开源。React 拥有较高的性能，代码逻辑非常简单，越来越多的人已开始关注和使用它。"
                />
                <CardMain
                    title="Node.js 是什么？"
                    artical="简单的说 Node.js 就是运行在服务端的 JavaScript。Node.js 是一个基于 Chrome JavaScript 运行时建立的一个平台。Node.js 是一个事件驱动 I/O 服务端 JavaScript 环境，基于 Google 的 V8 引擎，V8 引擎执行 Javascript 的速度非常快，性能非常好。"
                />
                <CardMain
                    title="Python 3 是什么？"
                    artical="Python 的 3.0 版本，常被称为 Python 3000，或简称 Py3k。相对于 Python 的早期版本，这是一个较大的升级。为了不带入过多的累赘，Python 3.0 在设计的时候没有考虑向下兼容。"
                />
                <CardMain
                    title="Java 是什么？"
                    artical="Java 是由 Sun Microsystems 公司于 1995 年 5 月推出的高级程序设计语言。Java 可运行于多个平台，如 Windows, Mac OS 及其他多种 UNIX 版本的系统。移动操作系统 Android 大部分的代码采用 Java 编程语言编程。"
                />
                <CardMain
                    title="C++ 是什么？"
                    artical="C++ 是一种高级语言，它是由 Bjarne Stroustrup 于 1979 年在贝尔实验室开始设计开发的。C++ 进一步扩充和完善了 C 语言，是一种面向对象的程序设计语言。C++ 可运行于多种平台上，如 Windows、MAC 操作系统以及 UNIX 的各种版本。"
                />
                <CardMain
                    title="C# 是什么？"
                    artical="C# 是一个简单的、现代的、通用的、面向对象的编程语言，它是由微软（Microsoft）开发的。"
                />
            </el-col>
        </el-row>
    </el-main>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";

import CardTest from "../components/CardTest.vue";
import CardPersonal from "../components/CardPersonal.vue";
import CardMain from "../components/CardMain.vue";
import CardAnnouncement from "@/components/CardAnnouncement.vue";
import CardNewestPost from "@/components/CardNewestPost.vue";
import CardCategory from "@/components/CardCategory.vue";
import CardTag from "@/components/CardTag.vue";
import CardWebsiteInfo from "@/components/CardWebsiteInfo.vue";
import CardPigeonhole from "@/components/CardPigeonhole.vue";

const isAbove767 = ref(true);

const updateIsAbove767 = () => {
    isAbove767.value = window.matchMedia("(min-width: 767px)").matches;
};

onMounted(() => {
    updateIsAbove767();
    window.addEventListener("resize", updateIsAbove767);
});

onUnmounted(() => {
    window.removeEventListener("resize", updateIsAbove767);
});
</script>

<style scoped>
.el-card {
    color: hsl(0, 0%, 0%);
    background-color: hsl(0, 0%, 100%);
    margin-top: 24px;
    border-radius: 12px;
    opacity: 0.95;
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (max-width: 767px) {
    .col1 {
        margin-right: auto;
    }
    .col2 {
        margin-right: auto;
    }
}
</style>
