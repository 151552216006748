<template>
    <el-card>
        <div style="text-align: left">
            <i :class="cardIcon" :style="{ color: cardItemColor }"></i>
            {{ cardTitle }}
        </div>
        <div style="text-align: left; margin-top: 12px; line-height: 1.6">
            <li v-for="(item, index) in messages" :key="index">
                {{ item }}
            </li>
        </div>
    </el-card>
</template>

<script lang="ts" setup>
const cardTitle = "分类";
const cardIcon = "fa-solid fa-layer-group";
const cardItemColor = `hsl(200, 100%, 60%)`;
const messages: string[] = ["编程", "生活", "经济", "感情", "经历"];
</script>
