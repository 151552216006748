<!-- src/components/NavBar.vue -->
<template>
    <el-header>
        <el-menu
            mode="horizontal"
            :default-active="activeIndex"
            :ellipsis="isEllipsis"
            :router="true"
            style="max-width: 1180px; margin: 0 auto"
        >
            <router-link to="/" style="margin-top: 6px">
                <el-button :link="true">
                    <el-avatar :src="avatarImg" :size="42" />
                    <el-text style="font-size: 18px; margin-left: 8px">梓峻的blog</el-text>
                </el-button>
            </router-link>
            <el-menu-item style="visibility: hidden" />
            <el-menu-item index="/">
                <i class="fa-solid fa-house" style="font-size: 12px; margin-right: 4px" />
                主页
            </el-menu-item>
            <el-menu-item index="timeline">
                <i class="fa-solid fa-bars-staggered" style="font-size: 12px; margin-right: 4px" />
                时间轴
            </el-menu-item>
            <el-menu-item index="tag">
                <i class="fa-solid fa-tag" style="font-size: 12px; margin-right: 4px" />
                标签
            </el-menu-item>
            <el-menu-item index="category">
                <i class="fa-solid fa-layer-group" style="font-size: 12px; margin-right: 4px" />
                分类
            </el-menu-item>
            <el-menu-item index="about">
                <i
                    class="fa-solid fa-up-right-from-square"
                    style="font-size: 12px; margin-right: 4px"
                />
                关于
            </el-menu-item>
        </el-menu>
    </el-header>
</template>

<script setup lang="ts">
import { ref, watchEffect, onMounted, onUnmounted } from "vue";
import avatarImg from "@/assets/hillsong.jpg";

const activeIndex = ref("/");
const isEllipsis = ref(false);

const updateScreenWidth = () => {
    let screenWidth = window.innerWidth;
    isEllipsis.value = false;

    if (screenWidth >= 1180) {
        isEllipsis.value = false;
    } else if (screenWidth >= 992 && screenWidth < 1180) {
        isEllipsis.value = false;
    } else if (screenWidth >= 768 && screenWidth < 992) {
        isEllipsis.value = false;
    } else {
        isEllipsis.value = true;
    }
};

onMounted(() => {
    window.addEventListener("resize", updateScreenWidth);
});

onUnmounted(() => {
    window.removeEventListener("resize", updateScreenWidth);
});

updateScreenWidth();
</script>

<style scoped>
.el-header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    outline: none;
    background-color: white;
    opacity: 95%;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.el-menu--horizontal > .el-menu-item:nth-child(2) {
    margin-right: auto;
}
</style>
