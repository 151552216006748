<template>
    <el-card>
        <div style="text-align: left">
            <i :class="cardIcon" :style="{ color: cardItemColor }"></i>
            {{ cardTitle }}
        </div>
        <div style="text-align: left; margin-top: 12px; line-height: 1.6">
            <li v-for="(item, index) in messages" :key="index">
                {{ item }}
            </li>
        </div>
    </el-card>
</template>

<script lang="ts" setup>
const cardTitle = "公告";
const cardIcon = "fa-solid fa-bullhorn";
const cardItemColor = `hsl(0, 100%, 50%)`;
const messages: string[] = [
    "学问是需要勤奋才能得到的，就像古人囊萤取光，勤奋夜读，读万卷书。",
    "萤窗：晋人车胤，家贫无钱买灯油，就捕捉许多萤火虫放在丝囊中，供夜读时照明。后世便常以萤窗、萤案比喻刻苦读书。",
];
</script>
