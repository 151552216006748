<template>
    <div>
        <h1>{{ post.title }}</h1>
        <p>{{ post.body }}</p>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            post: {},
        };
    },
    created() {
        const postId = this.$route.params.id;
        axios
            .get(`/api/posts/${postId}`)
            .then((response) => {
                this.post = response.data;
            })
            .catch((error) => {
                console.error(error);
            });
    },
};
</script>
