<!-- src/components/Footer.vue -->
<template>
    <el-footer>
        <p>Copyright &copy; 2024 chengzijun. All rights reserved.</p>
    </el-footer>
</template>

<script lang="ts" setup></script>

<style scoped>
.el-footer {
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    padding: 0px;
    text-align: center;
    margin-top: auto;
}
</style>
