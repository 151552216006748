<template>
    <el-card>
        <el-avatar :src="avatarImg" :size="128" />
        <div>
            <el-text style="font-size: 32px">程梓峻<br /></el-text>
            <el-text style="font-size: 16px">学问勤中得，萤窗万卷书。</el-text>
        </div>
        <div>
            <el-button link="true" style="font-size: 16px; margin: 12px; line-height: 1.5">
                文章<br />{{ postLimit }}
            </el-button>
            <el-button link="true" style="font-size: 16px; margin: 12px; line-height: 1.5">
                标签 <br />{{ tagLimit }}
            </el-button>
            <el-button link="true" style="font-size: 16px; margin: 12px; line-height: 1.5">
                分类 <br />{{ categoryLimit }}
            </el-button>
        </div>
        <div>
            <el-button type="primary" style="width: 220px; filter: saturate(80%), brightness(1.3)">
                <i class="fa-solid fa-up-right-from-square" style="margin-right: 6px" />
                联系我
            </el-button>
        </div>
        <div style="margin-top: 8px">
            <el-button link="true" style="font-size: 18px">
                <i class="fa-brands fa-github" />
            </el-button>
            <el-button link="true" style="font-size: 18px">
                <i class="fa-brands fa-weixin" />
            </el-button>
            <el-button link="true" style="font-size: 18px">
                <i class="fa-solid fa-envelope" />
            </el-button>
        </div>
    </el-card>
</template>

<script lang="ts" setup>
import avatarImg from "@/assets/hillsong.jpg";

const postLimit = 11;
const tagLimit = 22;
const categoryLimit = 33;
</script>
