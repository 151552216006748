<template>
    <el-card>
        <div style="text-align: left">
            <i :class="cardIcon" :style="{ color: cardItemColor }"></i>
            {{ cardTitle }}
        </div>
        <div style="text-align: left; margin-top: 12px; line-height: 1.6">
            <div v-for="(item, index) in messages" :key="index">
                {{ item }}
            </div>
        </div>
    </el-card>
</template>

<script lang="ts" setup>
const cardTitle = "归档";
const cardIcon = "fa-solid fa-box";
const cardItemColor = `hsl(200, 100%, 60%)`;
const messages: string[] = [
    "202408 ---------------- 2篇",
    "202407 ---------------- 1篇",
    "202406 ---------------- 4篇",
    "202405 ---------------- 1篇",
    "202402 ---------------- 2篇",
];
</script>
