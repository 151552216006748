import ElementPlus from "element-plus";
import "../element-variables.scss";

export default (app: {
    use: (arg0: {
        version: string;
        install: (
            app: import("vue").App<any>,
            options?: Partial<import("element-plus").ConfigProviderProps> | undefined
        ) => void;
    }) => void;
}) => {
    app.use(ElementPlus);
};
